@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap);
body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6dcd4;
    font-family: 'Montserrat';
}

#app_container {
    /* height: 1280px;
    width: 720px; */
    height: 1900px;
    width: 1060px;
    background-color: #f9eee5;
    container-type: inline-size;
}

.page_container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 50px;
    font-size: 30px !important;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.page_container::-webkit-scrollbar {
    display: none;
}

#challenge_end_page,
#character_bubble_page {
    overflow: hidden;
}

.reset_button {
    position: absolute;
    right: 15px;
    top: 0;
    display: inline;
    font-size: 50px;
    color: #e6dcd4;
    cursor: pointer;
}

/* --- WelcomePage --- */

#title_page h1 {
    font-size: 100px;
    margin: 0;
}

#title_page #hero_image {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -10px;
    margin-top: 50px;
}

#title_page #hero_image img {
    width: 100%;
}

/* --- ScenarioSelectPage --- */
.scenario_list {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: repeat(auto-fill, 250px);
}

.scenario_list>* {
    height: 320px;
    background-color: #e6dcd4;
}

.scenario_image {
    overflow: hidden;
}

.scenario_image img {
    height: 250px;
}

.scenario_title {
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* --- CharacterBubblePage --- */

.text_bubble {
    background-color: #353B5F;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
}

.large_character_text_bubble {
    width: 100%;
    border-radius: 40px;
    padding: 40px;
}

.large_character_text_bubble h1 {
    font-size: 50px;
}

.large_character_bubble_tail {
    width: 0px;
    height: 0px;
    margin-left: 260px;
    border-style: solid;
    border-width: 100px 120px 0 0;
    border-color: #353B5F transparent transparent transparent;
    transform: rotate(90deg);
    position: absolute;
    z-index: 99;
}

.large_character_container {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
}

.large_circle_image_frame {
    -webkit-clip-path: circle(180px at center);
            clip-path: circle(180px at center);
    background-color: #e6dcd4;
    height: 360px;
    width: 360px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 30px;
    top: -70px;
    z-index: 1;
}

.large_circle_image_frame img {
    width: 100%;
}

.large_character_image_frame {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.large_character_image_frame img {
    width: 100%;
}

/* --- DetailedExplanationPage --- */

.large_image_frame {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 500px;
    background-color: #e6dcd4;
    margin: 100px 0;
    overflow: hidden;
}

.large_image_frame img {
    height: 100%;
}

/* --- MultipleChoicePage --- */

.small_character_container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    color: #ffffff;
    margin-bottom: 50px;
}

.small_circle_image_frame {
    -webkit-clip-path: circle(120px at center);
            clip-path: circle(120px at center);
    background-color: #353B5F;
    height: 240px;
    width: 240px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.small_circle_image_frame img {
    width: 100%;
}

.small_character_text_bubble {
    width: 580px;
    border-radius: 40px;
    padding: 40px;
    background-color: #353B5F;
    margin-left: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 35px;
}

.multiple_choice_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.multiple_choice_list li {
    margin: 20px 0;
    background-color: #24919E;
    color: #ffffff;
    padding: 30px;
    border-radius: 30px;
    font-size: 25px;
}

.selected_green {
    background-color: #7EB79F !important;
}

.prompt_container {
    border: 1px solid #b09f8f;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 30px;
    font-size: 25px;
    white-space: pre-line;
}

.prompt_label {
    background-color: #b09f8f;
    color: #ffffff;
    padding: 5px;
    display: inline-block;
    float: right;
    position: relative;
    right: -30px;
    top: -30px;
}

.prompt_label_success {
    background-color: #3B702A;
}

.prompt_label_failed {
    background-color: #a52a2a;
}

.prompt_container_success {
    border: none;
    background-color: #CFD2BC;
}

.prompt_container_failed {
    border: none;
    background-color: #e9cbcb;
}

.prompt_instruction {
    margin-bottom: 20px;
}

.prompt_task {
    margin: 20px 0;
}

.prompt_pending_selection {
    border: 3px dashed #e4d8cb;
    padding: 10px;
}

.prompt_current_selection {
    border: 3px dashed #24919E;
    padding: 10px;
}

.prompt_highlight {
    background-color: #f0a1a1;
}

/* --- ChallengeEndPage --- */
.score_display {
    font-size: 90px;
    font-weight: 400;
    text-align: center;
    margin: 30px;
}

.loading_spinner {
    width: 100%;
    text-align: center;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-left: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 40%;
    border: 6px solid #b09f8f;
    border-color: #b09f8f transparent #b09f8f transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* --- ResultModal --- */
.result_modal {
    height: 100%;
    width: 100%;
    background-color: #0000008e;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.modal_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #e4d8cb;
    padding: 50px;
    width: 70%;
    height: auto;
    border-radius: 40px;
}

.modal_panel p {
    font-weight: bold;
    font-size: 40px;
    text-align: center;
}

/* --- PromptInjectionPage --- */
.prompt_choice_list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.prompt_choice_list li {
    background-color: #24919E;
    color: #ffffff;
    padding: 30px;
    font-size: 25px;
}

.prompt_container .category_label {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    text-align: center;
    padding: 10px;
    background-color: #e4d8cb;
}

.prompt_container .category_container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 0;
}

/* --- ExtendedMultipleChoicePage --- */
.multiple_choice_list h3 {
    margin: 10px 0;
}
